@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
body {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warn {
  width: 256.5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.warn small {
  width: 210px;
  color: red;
  font-size: 10px;
  text-align: center;
}

.container {
  background: gray;
  padding: 25px;
  border: 0.1px solid black;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px #000000;
}

.timer {
  height: 60.3px;
  width: 193.5px;
  background: white;
  font-size: 50px;
  padding: 3px 5px 5px 5px;
  border: 1px solid black;
  border-radius: 5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
 supported by Chrome, Edge, Opera and Firefox */
}
.timer .screen {
  margin-top: -4px;
  margin-left: -6px;
  height: 70.3px;
  width: 205.5px;
  position: absolute;
  background-color: rgba(173, 216, 230, 0.25);
  border-radius: 5px;
}
.timer select {
  font-size: 50px;
  text-align: center;
  width: 57px;
  border: 0;
  border-radius: 5px;
  color: black;
  background: white;
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}
.timer select::-ms-expand {
  /* for IE10 */
  display: none;
}
@media (pointer: coarse) {
  .timer select {
    font-size: 1.8rem;
    padding-top: 15px;
    padding-bottom: 3px;
  }
}
.timer.idle select {
  outline: 1px solid black;
}
.timer.expired {
  background-color: rgb(255, 240, 240);
  color: red;
}
.timer.expired select {
  background-color: rgb(255, 240, 240);
  color: red;
  border-color: red;
}

.buttons {
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
}
.buttons button {
  align-items: center;
  background-color: rgb(250, 250, 250);
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  justify-content: center;
  max-width: 100%;
  padding: 8px 18px;
  text-decoration: none;
  transition: all 0.2s;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}
.buttons button:first-child {
  border-radius: 15px 0 0 15px;
}
.buttons button:last-child {
  border-radius: 0 15px 15px 0;
}
.buttons button:hover {
  outline: 0;
  background-color: #FFFFFF;
  border-color: rgba(0, 0, 0, 0.19);
}
.buttons button:disabled {
  background: rgb(180, 180, 180);
  border-color: rgb(180, 180, 180);
}/*# sourceMappingURL=App.css.map */